<template>
	<edit-template @confirm="submit" @cancel="cancel" :cancelRemind="false" confirmText="提交" cancelText="取消">
		
    <div class="form-body" style="padding: 35rem 0 0 60rem">
      <h4>基础信息</h4>
      <br>
      <el-form label-width="100rem" ref="form" class="demo-ruleForm" :model="formData" :rules="rules">
        <el-form-item label="设置年月" prop="month">
          <el-date-picker v-model="formData.month" type="month" :editable="false" :clearable="false" :picker-options="pickerOptions" format="yyyy-MM" value-format="yyyyMM" placeholder="选择年月">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="校区：" prop="school_id">
          <el-select v-model="formData.school_id" @change="schoolChange">
            <el-option v-for="item in schoolConfig" :key="item.id" :label="item.name" :value="item.id">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="年级：" prop="grade_id">
          <el-select v-model="formData.grade_id">
            <el-option v-for="item in gradeConfig" :key="item.id" :label="item.name" :value="item.id">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="科目：" prop="subject_id">
          <el-select v-model="formData.subject_id">
            <el-option v-for="item in subjectConfig" :key="item.subject_id" :label="item.name" :value="item.subject_id">
          </el-option></el-select>
        </el-form-item>
        <br>
        <h4>学习习惯完成率</h4>
        <br>
        <el-form-item v-for="item in knackConfig" :label="item.name" :prop="item.key">
          <el-input-number v-model="formData[item.key]" :precision="0" :min="0" :max="100" @blur="inputNumberBlur($event,item.key)" class="inputNumber"></el-input-number>
          %
        </el-form-item>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      // 校区配置
      schoolConfig: [],
      // 年级配置
      gradeConfig: [],
      // 科目配置
      subjectConfig: [],
      // 获取处理类型
      knackConfig: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      },
      // 表单数据
      formData: {
        month: '',
        school_id: '',
        grade_id: '',
        subject_id: '',
      },
      // 表单验证规则
      rules: {
        month: [{required: true, message: '请设置年月', trigger: 'change'}],
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        grade_id: [{required: true, message: '请选择年级', trigger: 'change'}],
        subject_id: [{required: true, message: '请选择科目', trigger: 'change'}],
      }
    }
  },
  created() {
    this.getKnackConfig()
    this.getSchoolConfig()
    this.getSubjectConfig()
  },
  methods: {
    getKnackConfig() {
      this.$_axios2.get('api/educational-routine/knack-list').then(res => {
        if (res.data.status == 0) {
          this.knackConfig = res.data.data
          this.knackConfig.forEach(item => {
            this.$set(this.formData, item.key, 100)
          })
        }
      })
    },
    getSchoolConfig() {
      this.$_axios2.get('api/common/school').then(res => {
        let data = res.data.data
        this.schoolConfig = data.school;
      })
    },

    schoolChange(e) {
      this.formData.grade_id = ''
      this.$_axios2.get('api/common/school?school_id=' + e).then(res => {
        let data = res.data.data
        this.gradeConfig = data.grade;
      })
    },

    getSubjectConfig() {
      this.$_axios2.get('api/educational-routine/subject-select').then(res => {
        let data = res.data.data
        this.subjectConfig = data
      })
    },

    // 表单提交
    submit() {
      if (!this.formData[this.knackConfig[0].key] && !this.formData[this.knackConfig[1].key] && !this.formData[this.knackConfig[2].key] && !this.formData[this.knackConfig[3].key]) {
        this.$message.error({ message: '请至少设置一项学习习惯完成率', showClose: true })
        return;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$_axios2.post('api/educational-routine/add-routine', {...this.formData}).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '新增成功',
                onClose: () => {
                  this.$store.commit("setPage",1);
                  this.$router.go(-1)
                }
              })
            }
          })
        }
      })
    },
    // 取消按钮
    cancel() {
      this.$confirm('是否取消新增?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    },

    inputNumberBlur(e,key){
      if(e.target.ariaValueNow === 'undefined'){
        this.formData[key] = 0
      }
    }
  },
}
</script>

<style scoped>
.inputNumber {
  width: 15.06944444444444vw !important;
}
</style>
